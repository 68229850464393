import s from './Footer.module.css';
import logoFooter from '../../images/logo_footer.png';
import iccrc from '../../images/iccrc.png';
import * as c from '../../state/variables'


const Footer = () => {
	return <footer className={s.footer}>
		<div className="max_width_custom">
			<div className="w_50 inline terms sp_w_100 sp_padding_t">
				<div className="w_50 center inline">
					<a className="color_white" href="/terms-of-service">Terms of Service</a>
				</div>
				<div className="w_50 center inline">
					<a className="color_white" href="/privacy-policy">Privacy Policy</a>
				</div>
			</div>
			<div className="w_50 inline sp_w_100 middle center">
				<a href="/"><img className={s.logo} src={c.API_ADDRESS+c.API_VERSION+'/domains/'+c.BRAND+'/icons/logo_footer.png'} alt={c.BRAND + ' logo'}/></a>
				<img className={s.logo} src={iccrc} alt={'img'}/>
			</div>
			<div className="w_100 middle f_07 padding_hf_v color_white center">The website {c.DOMAIN} is owned
				and operated by CFIS-ORG IMMIGRATION CL. Calle Balmes 173, 08006, Barcelona, Spain.
			</div>
		</div>

	</footer>
}
export default Footer;
